import React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useRoutes, useNavigate } from 'react-router-dom';

import { ThemeSettings } from './theme/Theme';

import ScrollToTop from './components/shared/ScrollToTop';
import Router from './routes/Router';
import { useToken } from 'src/hooks/useAuthentication';
// import '../src/store/auth/expirationCheck';
import initializeInactivityCheck from '../src/store/auth/inactivityCheck';

import './app.css';
import 'react-quill/dist/quill.snow.css';
import { useAuthentication } from 'src/hooks/useAuthentication';
import { setToken } from './services/api-methods';
import { useSelector } from 'react-redux';

initializeInactivityCheck();

function App() {
  const token = useToken();
  setToken(token);

  const customizer = useSelector((state) => state.customizer);

  React.useEffect(() => {
    if (customizer.activeMode === 'light') {
      document.body.classList.add('light-mode');
      document.body.classList.remove('dark-mode');
    } else {
      document.body.classList.add('dark-mode');
      document.body.classList.remove('light-mode');
    }
  }, [customizer.activeMode]);

  const navigate = useNavigate();
  const routing = useRoutes(Router);
  const theme = ThemeSettings();

  const isAuthenticated = useAuthentication();

  React.useEffect(() => {
    if (
      !isAuthenticated &&
      window.location.pathname !== '/auth/forgot-password' &&
      !window.location.pathname.startsWith('/auth/two-steps')
      // window.location.pathname.startsWith('/reports/compliance/review')
    ) {
      if (!window.location.pathname.startsWith('/auth')) {
        localStorage.removeItem('postLoginRedirect');
        localStorage.setItem('postLoginRedirect', window.location.pathname);
      }
      navigate(`/auth/login`);
    }
  }, [isAuthenticated, navigate]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ScrollToTop>{routing}</ScrollToTop>
    </ThemeProvider>
  );
}

export default App;
